<template>
  <div class="content-layout">
    <v-dialog v-model="showModal" persistent width="600px">
      <v-card>
        <v-card-title>
          <span class="lfont ml-2" style="font-family:Noto Sans Lao;">{{
            $t("employee_ot.reject")
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  label="Notes"
                  auto-grow
                  outlined
                  v-model="remark"
                  rows="4"
                  row-height="15"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveChange">
            Save
          </v-btn>
          <v-btn color="dark" text @click="closeModal">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { emit } from "process";

export default {
  props: ["id", "dialogR"],
  data() {
    return {
      remark: "",
      server_errors: {
        remark: "",
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    saveChange() {
      this.$axios
        .get(`/line/manager/reject/employee/approval/${this.id}`, {
          params: {
            remark: this.remark,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$emit("close");
            this.$emit("success");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
  },
  computed: {
    showModal: function() {
      return this.dialogR;
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.content-layout {
  padding: 20px;
  font-family: "Noto Sans Lao" !important;
}
.btn {
  padding: 30px !important;
}
.btn-save {
  color: white !important;
  margin-right: 30px;
}
.user {
  color: blue;
}
</style>
