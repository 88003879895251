<template>
  <div>
    <v-row class="mx-2">
      <v-col cols="12" md="2" lg="2" sm="12" xs="12" class="mb-3">
        <label class="lfont">ເດືອນ/ປີ</label>
        <DatePicker class="date-time" style="width: 100%" type="month" valueType="format" slot="activator"
          :append-to-body="true" name="founding_date" v-model="date" @change="fetchApprove"></DatePicker>
        <!-- <label class="label-input">{{ $t("ot_report.year") }}</label>
          <v-select :items="years" outlined dense v-model="year"></v-select> -->
      </v-col>
      <v-col cols="12" md="3" lg="3">
        <label class="label-input">{{ $t("e-approve.label_select") }}</label>
        <v-select :items="todoStatus" outlined dense item-value="value" item-text="name" v-model="status"
          @change="searchFilterItem()"></v-select>
        <!-- @change="fetchEmployeeHourTotal" -->
      </v-col>


    </v-row>
    <v-row class="mx-2">
      <v-col cols="12" md="3" lg="3">
        <label class="label-input">{{ $t("Employee.branch") }}</label>
        <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="listBranch" hide-selected
          v-model="branch_id" @change="handleBranch($event)">
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3" lg="3">
        <label class="label-input">{{ $t("Employee.dept") }}</label>
        <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment" hide-selected
          v-model="department_id" @change="handleDepartment($event)">
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3" lg="3">
        <label class="label-input">{{
          $t("ReportScanInOut.textsearchByname")
        }}</label>
        <v-text-field outlined dense v-model="filter" prepend-inner-icon="mdi-magnify" @keypress.enter="searchFilterItem">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="section-form-content">
          <table class="table table-view" v-if="listApprove.length > 0">
            <thead>
              <tr>
                <th class="text-left">
                  ວັນທີ
                </th>
                <th class="text-left">
                  ເລກຂໍອະນຸມັດ
                </th>
                <th class="text-left">
                  ຫົວຂໍ້
                </th>
                <th class="text-left">
                  ລາຍລະອຽດ
                </th>
                <th class="text-left">
                  ໄຟລ໌ເອກະສານ
                </th>
                <th class="text-left">
                  ຜູ້ຂໍອະນຸມັດ
                </th>
                <th class="text-left">
                  ຜູ້ອະນຸມັດ
                </th>
                <th class="text-left">
                  ເຄື່ອງມື
                </th>
              </tr>
            </thead>
            <tbody class="table-body">
              <tr class="align-self-center" v-for="(item, idx) in listApprove" :key="idx">
                <td class="pa-3">
                  {{ moment(item.created_time).format("YYYY-MM-DD") }}
                  {{ moment(item.created_time).format("HH:mm:ss") }}
                </td>
                <td>{{ item.approval_number }}</td>
                <td class="lfont">{{ item.title }}</td>
                <td class="lfont">{{ item.description }}</td>
                <td>
                  <v-icon left @click="openFile(item.file_name)">
                    mdi-file-eye
                  </v-icon>
                </td>
                <td class="lfont">{{ item.employee.name }}</td>
                <td>
                  <p v-for="(approve_by_list, index) in item.approved_bys" class="lfont">
                    {{ approve_by_list.name }} {{ approve_by_list.surname }}
                    <v-chip small style="background-color: orange !important; color: #fff !important;"
                      v-if="approve_by_list.status == 'pending'">
                      pending
                    </v-chip>
                    <v-chip style="background-color: #0336ff !important; color: #fff !important;" small color="secondary"
                      v-if="approve_by_list.status == 'approved'">
                      Approved
                    </v-chip>
                    <v-chip small style="background-color: red !important;" v-if="approve_by_list.status == 'rejected'">
                      rejected
                    </v-chip>
                    <br /><span class="lfont font-weight-thin" style="font-size:80%;">ລະຫັດອ້າງອີງ:
                    </span>
                    <span v-if="approve_by_list.refer_number != null" style="font-size:80%; color:#0336ff">{{
                      approve_by_list.refer_number }}</span>

                    <br /><span class="lfont font-weight-thin" style="font-size:80%;" v-if="approve_by_list.refer_number != null ||
                      approve_by_list.status == 'rejected'
                      ">ວັນທີອະນຸມັດ:
                    </span>
                    <span v-if="approve_by_list.status == 'rejected'" style="font-size:80%; color:red">{{
                      moment(approve_by_list.approve_time).format(
                        "YYYY-MM-DD"
                      )
                    }}
                      {{
                        moment(approve_by_list.approve_time).format(
                          "HH:mm:ss"
                        )
                      }}</span>
                    <span v-if="approve_by_list.status == 'approved'" style="font-size:80%; color:#0336ff">{{
                      moment(approve_by_list.approve_time).format(
                        "YYYY-MM-DD"
                      )
                    }}
                      {{
                        moment(approve_by_list.approve_time).format(
                          "HH:mm:ss"
                        )
                      }}</span>

                    <!-- <span
                        v-if="
                          approve_by_list.status == 'pending' &&
                            approve_by_list.status == 'rejected' &&
                            approve_by_list.refer_number != null
                        "
                        class="font-weight-thin"
                        >******</span
                      > -->
                  </p>
                </td>
                <td style="white-space: nowrap !important;">
                  <span v-if="item.status == 'pending'" style="color: orange;">{{ item.status }}</span>
                  <span v-if="item.status == 'rejected'" style="color: red;">{{ item.status }}
                    <p v-for="(approve_by_list, index) in item.approved_bys">
                      <span v-if="approve_by_list.status == 'rejected'">
                        <span style="color:black !important;">Note :</span>
                        <span> {{ approve_by_list.remark }}</span>
                      </span>
                    </p>
                  </span>
                  <span v-if="item.status == 'approved'" style="color:#0336ff;">{{ item.status }}</span>
                  <span v-if="item.status == 'success'" style="color: #0336ff;">{{ item.status }}</span>
                </td>
                <td>
                  <span v-if="item.status == 'pending'">
                    <v-btn color="success accent-4" @click="modalApprove(item.id)">
                      <span class="lfont" style="color: white">{{ $t("employee_ot.approve") }}</span>
                    </v-btn>
                    <v-btn color="error accent-4" @click="modalReject(item.id)">
                      <span class="lfont" style="color: white">{{ $t("employee_ot.reject") }}</span>
                    </v-btn>
                  </span>
                  <span v-else>
                    <v-btn depressed color="success accent-4" @click="modalApprove(item.id)" disabled>
                      <span class="lfont" style="color: white">{{ $t("employee_ot.approve") }}</span>
                    </v-btn>
                    <v-btn text color="error accent-4" @click="modalReject(item.id)" disabled>
                      <span class="lfont" style="color: white">{{ $t("employee_ot.reject") }}</span>
                    </v-btn>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <defaultTableNoResult v-else />
          <Loading v-if="isLoading" />
        </div>

        <Approve @success="fetchApprove" @close="dialog = false" :id="id" :dialog="dialog" />

        <Reject @success="fetchApprove" @close="dialogR = false" :id="id" :dialogR="dialogR" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import Loading from "@/components/Loading";
import Reject from "./approve/Reject.vue";
import Approve from "./approve/Approve.vue";

export default {
  components: { DatePicker, defaultTableNoResult, Loading, Reject, Approve },
  data() {
    return {
      listBranch: [],
      branch_id: "",
      listDepartment: [],
      department_id: "",
      dialog: false,
      dialogR: false,
      id: "",
      isLoading: false,
      listApprove: [],
      filter: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      todoStatus: [
        {
          id: 0,
          name: this.$t("statusEleave.all"),
          value: "all",
        },
        {
          id: 1,
          name: this.$t("statusEleave.pending"),
          value: "pending",
        },
        {
          id: 2,
          name: this.$t("statusEleave.approved"),
          value: "approved",
        },
        {
          id: 3,
          name: this.$t("statusEleave.success"),
          value: "success",
        },
        {
          id: 3,
          name: this.$t("statusEleave.rejected"),
          value: "rejected",
        },
      ],
      // year: new Date().getFullYear(),
      // month: "",
      // months: [],
      status: "all",
    };
  },
  methods: {
    openFile(url) {
      window.open(url, "_blank", "noreferrer");
    },
    searchFilterItem() {
      this.fetchApprove();
      // this.pagination.current_page = 1;
    },
    handleBranch(branch_id) {
      this.branch_id = branch_id;
      this.fetchApprove();
      //this.pagination.current_page = 1;
    },
    handleDepartment(department_id) {
      this.department_id = department_id;
      this.fetchApprove();
      //this.pagination.current_page = 1;
    },
    fetchApprove() {
      this.isLoading = true;
      const year_month = moment(this.date).format("YYYY-MM");
      const items = {
        status: this.status,
        date: year_month,
        filter: this.filter,
        branch_id: this.branch_id,
        department_id: this.department_id
      };
      this.$axios
        .post(`/line/manager/list/employee/approvals`, items)
        .then((res) => {
          this.isLoading = false;
          if (res.status === 200) {
            this.listApprove = res.data.data;
          }
        })
        .catch((error) => {
          this.Loading = false;
        });
    },

    modalApprove(id) {
      this.dialog = true;
      this.id = id;
    },
    modalReject(id) {
      this.dialogR = true;
      this.id = id;
    },
    fetchDepartment() {
      this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
        }
      });
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    // this.getYears();
    this.fetchApprove();
    this.fetchDepartment();
    this.fetchBranch();
  },
};
</script>

<style lang="scss" scoped>
.table-body {
  tr {
    td {
      text-wrap: balance;
    }
  }
}

.custom_table {
  margin-top: 10px;
  border: 1px solid rgb(131, 128, 128);
  width: auto;
  height: auto;
  padding: 5px;
}

.tb-custom {
  border-collapse: collapse !important;
}

td th {
  white-space: nowrap !important;
  border: 1px solid black !important;
  padding: 5px !important;
}
</style>
